export type OtpPurpose = 
  'forgot-password' |
  'change-password' | 
  'profile-verification' | 
  'change-pin';

export type OtpChannel = 'mobile' | 'email';

export interface OtpChannelInfo {
  email: string,
  emailEnabled: boolean,
  mobile: string,
  mobileEnabled: boolean
}

export enum OtpStage {
  CHANNEL_SELECTION,
  OTP_VALIDATION
}

export interface OtpValidationDto {
  otp: string,
  otpValidationToken: string
}

export const OtpApiErrors = { 
  MAX_RETRIES_REACHED: 'error.otp-validation.max-retries-reached'
}