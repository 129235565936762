import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { HomeNavigationPage, LiveStream } from '../../models/home.model';
import { BaseResponse } from '../../models/http.model';
import { HttpService } from '../http/http.service';

export type MatchInfoListParam = {
  page: number,
  countPerPage: number,
  nowPlaying?: boolean,
  isPinned?: boolean,
  sportTypeList?: string[]
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private bottomNavigationViewToggle = new BehaviorSubject<boolean>(true);
  bottomNavigationViewToggle$ = this.bottomNavigationViewToggle.asObservable();

  constructor(
    private httpService: HttpService
  ){
  }

  private isFromRegister = new ReplaySubject<boolean>(1);
  isFromRegister$ = this.isFromRegister.asObservable();

  private currentPage = new Subject<HomeNavigationPage>();
  currentPage$ = this.currentPage.asObservable();

  private liveStreamList = new Subject<Array<LiveStream>>();
  liveStreamList$ = this.liveStreamList.asObservable();

  private shouldCheckChatSearch = new BehaviorSubject<boolean>(false);
  shouldCheckChatSearch$ = this.shouldCheckChatSearch.asObservable();

  setCurrentPage(currentPage: HomeNavigationPage) {
    this.currentPage.next(currentPage);
  }

  /**
   * Hides the bottom navigation part of the page.
   * Useful when we want a blank white page
   */
  hideBottomNavigation(): void {
    this.bottomNavigationViewToggle.next(false);
  }

  /**
   * Shows the bottom navigation part of the page.
   */
  showBottomNavigation(): void {
    this.bottomNavigationViewToggle.next(true);
  }

  getLiveStreamListWithKeyword(keyword: string): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().post('/user/stream/live/chat/info/search', {
      inputText: keyword,
      page: 1,
      countPerPage: 10 // TODO change later
    });
  }

  getPopularLiveStreamList(): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().post('/user/stream/live/chat/info/search', {
      inputText: '',
      isPinned: true,
      page: 1,
      countPerPage: 10 // TODO change later
    });
  }

  getLiveStreamList(page: number = 1, countPerPage: number = 20, isLive: boolean = true, sportTypeList: string[]) {
    
    const param: MatchInfoListParam = {
      page,
      countPerPage,
      nowPlaying: isLive
    };

    if( sportTypeList.length > 0 ) {
      param.sportTypeList = sportTypeList;
    }

    return this.httpService.forIntegrationService().post('/user/stream/live/match/info', param);
  }

  getAnnouncementList(): Observable<BaseResponse> {
    const timestamp = new Date().getTime().toString(); // used for cache busting (cb)
    return this.httpService.forClientApi().get(`/announcements?cb=${timestamp}`);
  }

  setLiveStreamList(list: Array<LiveStream>): void {
    this.liveStreamList.next(list);
  }

  getLiveStreamLink(matchId: string): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().get('/user/stream/live/' + matchId);
  }

  getLiveStreamDetails(matchId: string): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().post('/user/stream/live/match/info', {
      page: 1,
      countPerPage: 1,
      matchId
    });
  }

  getLiveStreamerLink(matchId: string): Observable<BaseResponse> {
    const timestamp = new Date().getTime().toString(); // used for cache busting (cb)
    return this.httpService.forIntegrationService().get(`/user/stream/live/streamer/${matchId}?cb=${timestamp}`);
  }

  getLiveStreamerDetails(matchId: string): Observable<BaseResponse> {
    return this.httpService.forIntegrationService().post('/user/stream/live/streamer/info', {
      page: 1,
      countPerPage: 1,
      streamerLiveId: matchId
    });
  }

  getQRCode(): Observable<BaseResponse> {
    return this.httpService.forClientApi().get('/account/share-link');
  }

  setIsFromRegisterTrue() {
    this.isFromRegister.next(true);
  }

  setIsFromRegisterFalse() {
    this.isFromRegister.next(false);
  }

  getRegexBlocking() {
    return this.httpService.forClientApi().get('/system/config/chat-blocking-filter-regex');
  }

  setShouldCheckChatSearch(value: boolean) {
    this.shouldCheckChatSearch.next(value);
  }

  getFeaturedLiveStreamList(sportTypeList: string[] = []): Observable<BaseResponse> {
    const param: MatchInfoListParam = {
      page: 1,
      countPerPage: 10,
      isPinned: true
    };

    if( sportTypeList.length > 0 ) {
      param.sportTypeList = sportTypeList;
    }

    return this.httpService.forIntegrationService().post('/user/stream/live/match/info', param);
  }

  getLiveStreamerList(sportTypeList: string[] = [], page: number = 1): Observable<BaseResponse> {
    const param: MatchInfoListParam = {
      page,
      countPerPage: 10
    };

    if( sportTypeList.length > 0 ) {
      param.sportTypeList = sportTypeList;
    }

    return this.httpService.forIntegrationService().post('/user/stream/live/streamer/info', param);
  }
  
  getFeaturedLiveStreamerList(sportTypeList: string[] = [], page: number = 1): Observable<BaseResponse> {
    const param: MatchInfoListParam = {
      page,
      countPerPage: 10,
      isPinned: true
    };

    if( sportTypeList.length > 0 ) {
      param.sportTypeList = sportTypeList;
    }

    return this.httpService.forIntegrationService().post('/user/stream/live/streamer/info', param);
  }

  getSportsTypeList() {
    return this.httpService.forIntegrationService().get('/user/stream/sport/type/list');
  }

  readAnnouncements(announcementIds: number[]): Observable<BaseResponse> {
    return this.httpService.forClientApi().post('/announcements/read', { announcementIds });
  }
}
