import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, filter, takeUntil, tap } from 'rxjs';
import { LocalizedMessages } from '../../models/i18n.model';
import { PuzzleCaptchaContent, PuzzleCaptchaState } from '../../models/puzzle-captcha.model';
import { CommonService } from '../../service/common/common.service';
import { ModalService } from '../../service/modal/modal.service';
import { PuzzleCaptchaService } from '../../service/puzzle-captcha/puzzle-captcha.service';
import { langxObj } from './puzzle-captcha_langx';

@Component({
  selector: 'app-puzzle-captcha',
  templateUrl: './puzzle-captcha.component.html',
  styleUrl: './puzzle-captcha.component.scss',
})
export class PuzzleCaptchaComponent implements OnDestroy {

  private destroy$ = new Subject<void>();
  private puzzleCaptchaContent: BehaviorSubject<PuzzleCaptchaContent> = new BehaviorSubject<PuzzleCaptchaContent>({
    title: '',
    info: '',
    boardImage: '',
    pieceImage: '',
    token: '',
    sliderKnobPosX: 0,
    puzzlePiecePosX: 0,
    isMouseDown: false,
    originX: 0,
    originY: 0,
    width: 280,
    trail: [],
    state: PuzzleCaptchaState.Default
  });
  retries: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  puzzleDisplayToggle$: Observable<boolean>;
  puzzleCaptchaContent$: Observable<PuzzleCaptchaContent> = this.puzzleCaptchaContent.asObservable();

  langx: LocalizedMessages = {};

  mediaServer: string = '';

  isClickOnly: boolean = false;

  constructor(
    private commonService: CommonService,
    private router: Router,
    private puzzleCaptchaService: PuzzleCaptchaService,
    private modalService: ModalService,
  ) {
    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);

    this.commonService.currentLanguage$
      .pipe(takeUntil(this.destroy$))
      .subscribe(currentLanguage => this.langx = langxObj[currentLanguage]);
    
    this.puzzleDisplayToggle$ = puzzleCaptchaService.puzzleDisplayToggle$;
    this.monitorNavigation();
    this.monitorDisplay();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private monitorNavigation(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => this.puzzleCaptchaService.hidePuzzleCaptcha());
  }

  private monitorDisplay(): void {
    this.puzzleCaptchaService.puzzleDisplayToggle$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isShowPuzzleCaptcha) => {
          if (isShowPuzzleCaptcha) {
            this.showPuzzleCaptcha();
          }
        },
      });
  }

  private setDefault(): void {
    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      title: this.langx['PUZZLE_CAPTCHA_TITLE'],
      info: this.langx['PUZZLE_CAPTCHA_INFO_TEXT'],
      state: PuzzleCaptchaState.Default
    });
  }

  private setTried(): void { 
    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      info: this.langx['PUZZLE_CAPTCHA_TRY_AGAIN_TEXT'],
      state: PuzzleCaptchaState.Tried
    });
  }

  private setActive(): void {
    this.updateState(PuzzleCaptchaState.Active);
  }

  private setLoading(): void {
    this.updateState(PuzzleCaptchaState.Loading);
  }

  private setVerifying(): void {
    this.updateState(PuzzleCaptchaState.Verifying);
  }

  private setSuccess(): void {
    this.updateState(PuzzleCaptchaState.Success);
  }

  private setFailed(): void {
    this.updateState(PuzzleCaptchaState.Fail);
  }

  private updateState(newState: PuzzleCaptchaState): void {
    const current = this.puzzleCaptchaContent.value;
    this.puzzleCaptchaContent.next({ ...current, state: newState });
  }

  private refreshPuzzleCaptcha(): void {
    this.retries.next(this.retries.value + 1);
    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      sliderKnobPosX: 0,
      puzzlePiecePosX: 0,
      trail: []
    });

    this.setLoading();
    this.retrievePuzzleImages();
  }

  private retrievePuzzleImages(): void {
    this.puzzleCaptchaService.retrievePuzzleImage().pipe(
      tap(response => this.handlePuzzleImageResponse(response)),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  private setToken(token: string): void {
    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      token
    });
  }

  private handlePuzzleImageResponse(response: any): void {
    if (response.isSuccess) {
      this.setPuzzleImage(response.data.boardImageBase64, response.data.pieceImageBase64);
      this.setToken(response.data.token);
      this.retries.value > 0 ? this.setTried() : this.setDefault();
    } else {
      this.handlePuzzleImageErrors(response.errors);
    }
  }

  private handlePuzzleImageErrors(errors: any[]): void {
    this.puzzleCaptchaService.hidePuzzleCaptcha();
    if (errors.length > 0) {
      this.modalService.showError(errors[0].message).subscribe();
    }
  }

  onPuzzleCaptchaRefreshButtonClick(): void {
    if (this.puzzleCaptchaContent.value.state !== PuzzleCaptchaState.Loading) {
      this.refreshPuzzleCaptcha();
    }
  }

  onPuzzleCaptchaCloseButtonClick(): void {
    this.puzzleCaptchaService.hidePuzzleCaptcha();
  }

  private setPuzzleImage(boardImageBase64: string, pieceImageBase64: string): void {
    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      boardImage: boardImageBase64,
      pieceImage: pieceImageBase64
    });
  }

  dragPuzzleCaptchaStart($event: MouseEvent | TouchEvent): void {
    if (this.isSuccess()) {
      return;
    }

    const coordinates = this.getEventCoordinates($event);
    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      originX: coordinates.x,
      originY: coordinates.y,
      isMouseDown: true
    });
    this.setActive();

    this.isClickOnly = true;

    $event.preventDefault();
  }

  dragPuzzleCaptchaMove($event: MouseEvent | TouchEvent): void {
    if (!this.puzzleCaptchaContent.value.isMouseDown || this.isSuccess()) {
      return;
    }

    const coordinates = this.getEventCoordinates($event);
    const moveX = coordinates.x - this.puzzleCaptchaContent.value.originX;
    const moveY = coordinates.y - this.puzzleCaptchaContent.value.originY;

    if (moveX < 0 || moveX + 40 > this.puzzleCaptchaContent.value.width) {
      return;
    }
    
    this.puzzleCaptchaContent.value.sliderKnobPosX = moveX - 1;
    this.puzzleCaptchaContent.value.puzzlePiecePosX = this.calculatePuzzlePiecePosX(moveX);

    const currentValue = this.puzzleCaptchaContent.value;
    const newTrail = [...currentValue.trail, moveY];

    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      trail: newTrail,
      originY: coordinates.y,
      isMouseDown: true
    });

    this.isClickOnly = false;

    $event.preventDefault();
  }

  dragPuzzleCaptchaEnd(): void {
    if (!this.puzzleCaptchaContent.value.isMouseDown  || this.isSuccess() || this.isClickOnly) {
      return;
    }

    this.puzzleCaptchaContent.next({
      ...this.puzzleCaptchaContent.value,
      isMouseDown: false
    });

    this.verifyPuzzleCaptcha(this.getPayload());
  }

  private getEventCoordinates(event: MouseEvent | TouchEvent): { x: number, y: number } {
    return 'touches' in event ?
      { x: event.touches[0].clientX, y: event.touches[0].clientY } :
      { x: event.clientX, y: event.clientY };
  }

  private calculatePuzzlePiecePosX(moveX: number): number {
    return ((this.puzzleCaptchaContent.value.width - 40 - 20) / (this.puzzleCaptchaContent.value.width - 40)) * moveX;
  }

  private verifyPuzzleCaptcha(payload: string): void {
    this.commonService.showLoading();
    this.setVerifying();

    this.puzzleCaptchaService.verifyPuzzleCaptcha(payload).pipe(
      tap(response => this.handleVerificationResponse(response)),
      takeUntil(this.destroy$)
    ).subscribe();

  }

  private handleVerificationResponse(response: any): void {
    this.commonService.hideLoading();

    if (response.isSuccess) {
      this.setSuccess();
      setTimeout(() => this.puzzleCaptchaService.setVerificationStub(response.data.verificationStub), 800);
    } else {
      this.setFailed();
      setTimeout(() => this.refreshPuzzleCaptcha(), 1000);
    }

  }

  private getPayload(): string {

    const base64Data = btoa(JSON.stringify({
      trail: this.puzzleCaptchaContent.value.trail,
      verify: this.puzzleCaptchaContent.value.puzzlePiecePosX,
      token: this.puzzleCaptchaContent.value.token,
    }));

    return (
      btoa(
        this.puzzleCaptchaContent.value.token.substring(0, 10) +
        base64Data +  
        this.puzzleCaptchaContent.value.token.substring(this.puzzleCaptchaContent.value.token.length - 10)
      )
    );
    
  }

  private showPuzzleCaptcha(): void {
    this.setDefault();
    this.refreshPuzzleCaptcha();
  }

  isLoading(){
    return this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Loading;
  }

  isVerifying(){
    return this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Verifying;
  }

  isDefault(){
    return this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Default;
  }

  isActive() {
    return this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Active ||
      this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Default ||
      this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Tried || 
      this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Verifying;
  }

  isSuccess(){
    return this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Success;
  }

  isFailed(){
    return this.puzzleCaptchaContent.value.state == PuzzleCaptchaState.Fail;
  }

}
