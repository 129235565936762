import { LocalizedMessagesBundle } from '../../models/i18n.model';

export const langxObj: LocalizedMessagesBundle = {
  'zh-cn': {
    'OTP_SENT_SUCCESSFULY_VIA_SMS': '验证码已以手机短信传送至您的手机装置。',
    'OTP_SENT_SUCCESSFULY_VIA_EMAIL': '验证码已传送至您的注册邮箱',
    'OTP_CHANNEL_VIA_EMAIL': '电子邮件',
    'OTP_CHANNEL_VIA_SMS': '手机短信',
    'OTP_VALIDATION_SUCCESSFUL': '验证码验证成功。',
    'OTP_VALIDATION_SUCCESSFUL_CONTINUE_LABEL': '下一步',
    'OTP_VERIFY_LABEL': '验证',
    'OTP_EXPIRATION_HINT': '该验证码将在 5 分钟后过期',
    'OTP_RESEND_LABEL': '重新发送验证码'
  },
  'en-gb': {
    'OTP_SENT_SUCCESSFULY_VIA_SMS': 'An OTP has been sent to your mobile number',
    'OTP_SENT_SUCCESSFULY_VIA_EMAIL': 'An OTP has been sent to your email address',
    'OTP_CHANNEL_VIA_EMAIL': 'Via EMAIL',
    'OTP_CHANNEL_VIA_SMS': 'Via SMS',
    'OTP_VALIDATION_SUCCESSFUL': 'Your code has been successfully verified',
    'OTP_VALIDATION_SUCCESSFUL_CONTINUE_LABEL': 'Continue',
    'OTP_VERIFY_LABEL': 'Verify',
    'OTP_EXPIRATION_HINT': 'This code will expire in 5 minutes',
    'OTP_RESEND_LABEL': 'Resend Code'
  }
}