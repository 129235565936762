<div class="otp-wrapper">
  @if(viewToggle | async) {
    @switch (currentStage) {
        @case (OtpStage.CHANNEL_SELECTION) {
          <div class="stage otp-channel-selection">
              <div class="otp-channels-wrapper">
                  <div class="otp-channel mobile" [class.selected]="selectedOtpChannel === 'mobile'" [class.disabled]="!otpChannelInfo?.mobileEnabled" (click)="onMobileChannelClick()">
                      <div class="left">
                          <img class="icon-mobile" src="{{ mediaServer }}/zeta/user/forgot-password/icon_phone{{ otpChannelInfo?.mobileEnabled? '': '-disabled' }}.png" alt="">
                      </div>
                      <div class="right">
                          <div class="text text-bold text-white text-heavy">{{ langx['OTP_CHANNEL_VIA_SMS'] }}</div>
                          <div class="text text-bold text-white">{{ otpChannelInfo?.mobile }}</div>
                      </div>
                  </div>
          
                  <div class="otp-channel email" [class.selected]="selectedOtpChannel === 'email'" [class.disabled]="!otpChannelInfo?.emailEnabled" (click)="onEmailChannelClick()">
                      <div class="left">
                          <img class="icon-email" src="{{ mediaServer }}/zeta/user/forgot-password/icon_envelope{{ otpChannelInfo?.emailEnabled? '': '-disabled' }}.png" alt="">
                      </div>
                      <div class="right">
                          <div class="text text-bold text-white text-heavy">{{ langx['OTP_CHANNEL_VIA_EMAIL'] }}</div>
                          <div class="text text-bold text-white">{{ otpChannelInfo?.email }}</div>
                      </div>
                  </div>
              </div>
          </div>
        }
    
        @case (OtpStage.OTP_VALIDATION) {
            <div class="stage otp-validation">
                <div class="otp-validation-wrapper">
                    <form [formGroup]="otpValidationForm" (submit)="onValidateOtpClick()">
                        <div class="form-group otp">
                            <input (paste)="onOtpInputPaste($event, 0)" (input)="onOtpFieldInputInIndex($event, 0)" (keyup)="onOtpFieldKeyUpInIndex($event, 0)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[0]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl0">
                            <input (paste)="onOtpInputPaste($event, 1)" (input)="onOtpFieldInputInIndex($event, 1)" (keyup)="onOtpFieldKeyUpInIndex($event, 1)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[1]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl1">
                            <input (paste)="onOtpInputPaste($event, 2)" (input)="onOtpFieldInputInIndex($event, 2)" (keyup)="onOtpFieldKeyUpInIndex($event, 2)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[2]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl2">
                            <input (paste)="onOtpInputPaste($event, 3)" (input)="onOtpFieldInputInIndex($event, 3)" (keyup)="onOtpFieldKeyUpInIndex($event, 3)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[3]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl3">
                            <input (paste)="onOtpInputPaste($event, 4)" (input)="onOtpFieldInputInIndex($event, 4)" (keyup)="onOtpFieldKeyUpInIndex($event, 4)" [appAutofocusOnEmit]="otpInputFieldFocusSignals$[4]" [selectAllOnFocus]="true" maxlength="1" type="text" class="text-center form-control" formControlName="otpControl4">
                        </div>

                        @if (shouldShowOtpErrorMessage) {
                            <p class="text-red alert-text text-smaller">
                                <span class="material-symbols-outlined">error</span>
                                {{ otpErrorMessage }}
                            </p>
                        }

                        <div class="message-hint-wrapper">
                            <div class="message-hint text-center text-small">{{ langx['OTP_EXPIRATION_HINT'] }}</div>
                        </div>

                        <div class="form-group resend">
                            <div class="text-center text-smaller">
                                <button type="button" class="btn btn-just-text" (click)="onResendOtpClick()" [disabled]="timeRemaining$ | async">
                                    {{ langx['OTP_RESEND_LABEL'] }}
                                    @if(timeRemaining$ | async) {
                                        <span class="timer">in {{(timeRemaining$ | async) | date: 'm:ss'}}</span>
                                    }
                                </button>
                            </div>
                        </div>

                        <div class="form-group submit">
                            <button class="btn btn-primary" [disabled]="otpValidationForm.invalid">{{ langx['OTP_VERIFY_LABEL'] }}</button>
                        </div>
                    </form>
            </div>
        </div>
        }
    
      }
  }


</div>