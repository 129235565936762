import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { OtpPurpose, OtpChannel, OtpValidationDto, OtpChannelInfo, OtpStage } from '../../models/otp.model';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { BaseResponse } from '../../models/http.model';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(
    private httpService: HttpService
  ) { }

  private otpComponentViewToggle: Subject<boolean> = new Subject();
  otpComponentViewToggle$ = this.otpComponentViewToggle.asObservable();

  private otpChannelInfo: Subject<OtpChannelInfo> = new Subject();
  otpChannelInfo$ = this.otpChannelInfo.asObservable();

  private autoSelectedOtpChannel: Subject<OtpChannel> = new Subject();
  autoSelectedOtpChannel$ = this.autoSelectedOtpChannel.asObservable();

  private currentStage: ReplaySubject<OtpStage> = new ReplaySubject();
  currentStage$ = this.currentStage.asObservable();

  /**
   * presents the view with channel selection
   * and starts with the stage OtpStage.CHANNEL_SELECTION
   * 
   * @param otpChannelInfo 
   */
  startWithChannelSelection(otpChannelInfo: OtpChannelInfo) {
    this.otpChannelInfo.next(otpChannelInfo);
    this.switchStage(OtpStage.CHANNEL_SELECTION);
    this.showComponent();
  }

  /**
   * Will auto send the otp to the selected channel
   * and will skip to the stage OtpStage.OTP_VALIDATION
   * 
   * @param preselectedOtpChannel 
   */
  startWithAutoSelectedChannel(autoSelectedOtpChannel: OtpChannel) {
    this.autoSelectedOtpChannel.next(autoSelectedOtpChannel);
    this.switchStage(OtpStage.OTP_VALIDATION);
  }

  showComponent() {
    this.otpComponentViewToggle.next(true);
  }

  hideComponent() {
    this.otpComponentViewToggle.next(false);
  }

  switchStage(otpStage: OtpStage) {
    this.currentStage.next(otpStage);
  }

  sendOtp(purpose: OtpPurpose, otpChannel: OtpChannel, forgotPasswordToken?: string): Observable<BaseResponse<any>> {
    const sendOtpDto = {
      thruSms: otpChannel == 'mobile',
      thruEmail: otpChannel == 'email',
      forgotPasswordToken
    }

    let url = '/user/otp/send';
    if(purpose == 'forgot-password') {
      url = '/user/forgot-password/otp/send';
    }

    return this.httpService.forIntegrationService().post(url, sendOtpDto);
  }

  validateOtp(purpose: OtpPurpose, otpValidationDto: OtpValidationDto): Observable<BaseResponse<any>> {

    return this.httpService.forClientApi().post(`/otp/validate/${purpose}`, {
      otp: otpValidationDto.otp,
      forgotPasswordToken: otpValidationDto.otpValidationToken,
      otpValidationToken: otpValidationDto.otpValidationToken
    });
  }
}
