import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal } from '@angular/core';
import { BehaviorSubject, map, Observable, Subject, takeUntil, takeWhile, timer } from 'rxjs';
import { LocalizedMessages } from '../../models/i18n.model';
import {
  OtpPurpose,
  OtpChannelInfo,
  OtpChannel,
  OtpStage,
  OtpValidationDto,
  OtpApiErrors
} from '../../models/otp.model';
import { CommonService } from '../../service/common/common.service';
import { ModalService } from '../../service/modal/modal.service';
import { langxObj } from './otp_langx';
import { OtpService } from '../../service/otp/otp.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiError } from '../../models/http.model';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.scss'
})
export class OtpComponent implements OnInit, OnDestroy {

  @Input() purpose!: OtpPurpose;
  @Input() forgotPasswordToken?: string;
  @Input() successMessage?: string;

  @Output() stageChange: EventEmitter<OtpStage> = new EventEmitter();
  @Output() successOtpValidation: EventEmitter<string> = new EventEmitter();
  @Output() errorSendOtp: EventEmitter<string> = new EventEmitter();
  @Output() errorOtpValidation: EventEmitter<string> = new EventEmitter();
  @Output() successSendOtp: EventEmitter<string> = new EventEmitter();
  @Output() channelSelected: EventEmitter<OtpChannel> = new EventEmitter();

  readonly OtpStage = OtpStage;

  apiErrors$ = signal([] as ApiError[]);

  currentStage: OtpStage = OtpStage.CHANNEL_SELECTION;
  otpChannelInfo?: OtpChannelInfo;
  selectedOtpChannel?: OtpChannel;

  currentLanguage: string = '';
  langx!: LocalizedMessages;

  mediaServer: string = '';

  otpValidationForm!: FormGroup;
  shouldShowOtpErrorMessage: boolean = false;
  otpErrorMessage: string = '';
  otpInputFieldFocusSignals$!: any[];
  timeRemaining$ = new Observable<number>();
  otpValidationToken: string = '';
  viewToggle: Observable<boolean> = this.otpService.otpComponentViewToggle$;

  destroy$ = new Subject<void>();

  constructor(
    private otpService: OtpService,
    private commonService: CommonService,
    private modalService: ModalService,
    private formBuilder: FormBuilder
  ) {
    this.commonService.currentLanguage$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(currentLanguage => {
      this.currentLanguage = currentLanguage;
      this.langx = langxObj[this.currentLanguage];
    });

    this.commonService.mediaServer$.subscribe(mediaServer => this.mediaServer = mediaServer);
  }

  ngOnInit() {
    this.initaliazeotpInputFieldFocusSignals();
    this.initializeOtpValidationForm();

    this.otpService.otpChannelInfo$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((otpChannelInfo: OtpChannelInfo) => {
      this.otpChannelInfo = otpChannelInfo;
    });

    this.otpService.autoSelectedOtpChannel$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((selectedOtpChannel: OtpChannel) => {
      this.selectedOtpChannel = selectedOtpChannel;
      this.sendOtp(this.selectedOtpChannel);
    });

    this.otpService.currentStage$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((otpStage: OtpStage) => {
      this.switchStage(otpStage);
    })
  }

  initaliazeotpInputFieldFocusSignals() {
    this.otpInputFieldFocusSignals$ = [
      new BehaviorSubject<boolean>(true),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false),
      new BehaviorSubject<boolean>(false)
    ];
  }

  switchStage(otpStage: OtpStage) {
    this.currentStage = otpStage;

    if(this.currentStage == OtpStage.OTP_VALIDATION) {
      this.initaliazeotpInputFieldFocusSignals();
      this.initializeOtpValidationForm();
    }

    this.stageChange.emit(this.currentStage);
  }

  sendOtp(otpChannel: OtpChannel, isResend: boolean = false) {
    this.selectedOtpChannel = otpChannel;
    this.commonService.showLoading();

    this.otpService.sendOtp(this.purpose, otpChannel, this.forgotPasswordToken)
      .subscribe( response => {
        if(response.isSuccess) {
          this.channelSelected.emit(otpChannel);
          
          const message = otpChannel == 'email'? 
            this.langx['OTP_SENT_SUCCESSFULY_VIA_EMAIL'] :
            this.langx['OTP_SENT_SUCCESSFULY_VIA_SMS']

          this.modalService.showInformation(
            message,
            this.langx['OTP_VALIDATION_SUCCESSFUL_CONTINUE_LABEL']
          ).subscribe(() => {
            this.successSendOtp.emit(message);
            if(isResend) {
              return;
            }

            this.switchStage(OtpStage.OTP_VALIDATION);
            this.otpService.showComponent();
          });

        } else {
          this.modalService.showError( response?.errors?.[0].message ?? '' ).subscribe(() => {
            this.errorSendOtp.emit( response?.errors?.[0].code ?? '' );
          });
        }

        this.commonService.hideLoading();
      });
  }

  onMobileChannelClick() {
    if(this.otpChannelInfo?.mobileEnabled) {
      this.sendOtp('mobile');
    }
  }

  onEmailChannelClick() {
    if(this.otpChannelInfo?.emailEnabled) {
      this.sendOtp('email');
    }
  }

  initializeOtpValidationForm() {
    this.otpValidationForm = this.formBuilder.group({});
    this.otpInputFieldFocusSignals$.forEach((item, index) => {
      const control = new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0-9]{1}$')])
      this.otpValidationForm.addControl(`otpControl${index}`, control);
    });
    this.timeRemaining$ = new Observable<number>();
  }

  onOtpFieldInputInIndex($event: Event, index: number) {
    const targetInputValue = ($event.target as HTMLInputElement).value;
    if (targetInputValue.match(/^[A-Za-z0-9]{1}$/g) && index < (this.otpInputFieldFocusSignals$.length - 1)) {
      this.otpInputFieldFocusSignals$[index + 1].next(true);
      this.shouldShowOtpErrorMessage = false;
    }
  }
  
  /**
   * Explicitly used for handling backspace because
   * onInput doesn't register an input event if there's no deleted value 
   * when Backspace is pressed
   * 
   * @param $event 
   * @param index 
   */
  onOtpFieldKeyUpInIndex($event: KeyboardEvent, index: number) {
    if(!($event.target as HTMLInputElement).value) {
      if($event.key === 'Backspace' && index > 0) {
        this.otpInputFieldFocusSignals$[index - 1].next(true);
      }
    }
  }

  onResendOtpClick() {
    const countdownDuration = 5 * 60; // 5 minutes
    this.timeRemaining$ = timer(0, 1000).pipe(map(n => (countdownDuration - n) * 1000), takeWhile(n => n >= 0));
    this.otpValidationForm.reset();
    this.otpErrorMessage = '';
    this.shouldShowOtpErrorMessage = false;

    this.sendOtp(this.selectedOtpChannel!, true);
  }

  onOtpInputPaste($event: ClipboardEvent, index: number) {
    if (index <= (this.otpInputFieldFocusSignals$.length - 1)) {
      const formControls = Object.keys(this.otpValidationForm.controls).slice(index); //get all controls start @ index
      const values = $event.clipboardData?.getData('text').split('') ?? [];

      for (let i = 0; i < formControls.length; i++) {
        if (index < this.otpInputFieldFocusSignals$.length - 1) {
          this.otpInputFieldFocusSignals$[index].next(true);
          index++;
        }
        this.otpValidationForm.get(formControls[i])?.setValue(values[i]);
      }
    }
  }

  onValidateOtpClick() {
    const otpValidationDto = {} as OtpValidationDto;
    otpValidationDto.otpValidationToken = (this.purpose == 'forgot-password'? 
      this.forgotPasswordToken: this.otpValidationToken) ?? '';
    
    otpValidationDto.otp = Object.keys(this.otpValidationForm.controls)
      .map(control => this.otpValidationForm.get(control)?.value).join('');

    this.validateOtp(otpValidationDto);
  }

  validateOtp(otpValidationDto: OtpValidationDto) {
    this.commonService.showLoading();
    this.otpService.validateOtp(this.purpose, otpValidationDto).subscribe(response => {
      if (response.isSuccess) {
        this.modalService.showSuccess(
          this.successMessage ?? this.langx['OTP_VALIDATION_SUCCESSFUL'], 
          this.langx['OTP_VALIDATION_SUCCESSFUL_CONTINUE_LABEL'])
          .subscribe(() => {
            this.otpService.hideComponent();
            this.successOtpValidation.emit(response?.data?.otpValidationToken ?? otpValidationDto.otpValidationToken);
          });

      } else {
        this.apiErrors$.set(response.errors ?? []);
        this.otpErrorMessage = this.apiErrors$()?.[0].message;

        const errorCode = response?.errors?.[0].code ?? '';
        if(errorCode == OtpApiErrors.MAX_RETRIES_REACHED) {
          this.modalService.showError(this.otpErrorMessage).subscribe(() => {
            this.errorOtpValidation.emit(errorCode);
          });
        } else {
          this.shouldShowOtpErrorMessage = true;
          this.errorOtpValidation.emit(errorCode);
        }
      }

      this.commonService.hideLoading();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  
}
